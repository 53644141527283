import '@/styles/styles.scss'
import '@/plugins'
import Vue from 'vue'
import vueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import '@core/components-styles/ckEditorCustom.css'
import '@core/components-styles/vueSelectCustom.css'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import directives from '@/directives'
import XwSearch from '@core/components/search-form/SearchForm.vue'
import XwForm from '@core/components/xw-form/XwForm.vue'
import XwTable from '@core/components/xw-table/XwTable.vue'
import XwTabs from '@core/components/xw-tabs/XwTabs.vue'
import XwStatus from '@core/components/xw-status/XwStatus.vue'
import NewPagination from '@core/components/Pagination/index.vue'
import AppView from '@/components/AppView.vue'
import XWUI from '@prettyy/ui'
import '@prettyy/ui/dist/css/index.css'
import XwPlatform from '@core/components/xw-platform/Platform.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import App from './App.vue'

VXETable.config({
  width: 150,
  loadingText: '加载中...',
  table: {
    tooltipConfig: {
      enterable: true,
    },
  },
})

Vue.use(VXETable)
Vue.use(directives)
Vue.use(XWUI)

Vue.component('VueSelect', vueSelect)
Vue.component('XwSearch', XwSearch)
Vue.component('XwForm', XwForm)
Vue.component('XwTable', XwTable)
Vue.component('XwTabs', XwTabs)
Vue.component('XwStatus', XwStatus)
Vue.component('NewPagination', NewPagination)
Vue.component('AppView', AppView)
Vue.component('XwPlatform', XwPlatform)

// 滚动加载更多
Vue.directive('loadmore', {
  bind(el, binding) {
    const bodyWrapper = el.querySelector('.vxe-table--body-wrapper') || el.querySelector('.loadMore')
    bodyWrapper.addEventListener('scroll', function () {
      if (this.scrollHeight - this.scrollTop <= this.clientHeight + 20) {
        binding.value()
      }
    })
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
