export const statusOptions = [
  { title: '未开始', value: 0 },
  { title: '进行中', value: 1 },
  { title: '已完成', value: 2 },
]

export const compareOptions = [
  { label: '>', value: '大于' },
  { label: '=', value: '等于' },
  { label: '<', value: '小于' },
]

export const operateLevelOptions = [
  { label: 'S0', value: 0 },
  { label: 'S1', value: 1 },
  { label: 'S2', value: 2 },
  { label: 'S3', value: 3 },
  { label: 'S4', value: 4 },
  { label: 'S5', value: 5 },
  { label: 'S6', value: 6 },
  { label: 'S7', value: 7 },
]

export const videoStatusOptions = [
  { title: '公开', value: 0 },
  { title: '私享', value: 1 },
  { title: '不公开列出', value: 2 },
]

export const videoRangeOptions = [
  { title: '公开', value: '公开' },
  { title: '私享', value: '私享' },
  { title: '不公开列出', value: '不公开列出' },
]

export const upLoadPathOptions = [
  { title: 'Reels', value: 'Reels' },
  { title: 'Watch', value: 'Watch' },
]

export const lengthOptions = [
  { text: '10', value: 10 },
  { text: '15', value: 15 },
  { text: '20', value: 20 },
  { text: '25', value: 25 },
  { text: '30', value: 30 },
]

export const pointTypeOptions = [
  { title: '文本输入', value: 'textField' },
  { title: '多行文本输入', value: 'textArea' },
  { title: '文本选择', value: 'select' },
  { title: '单项选择', value: 'radio' },
  { title: '多项选择', value: 'checkbox' },
  { title: '富文本编辑', value: 'richText' },
  { title: '文件上传', value: 'fileInput' },
]

export const isOvertimeOptions = [
  { title: '未超时', value: 0 },
  { title: '超时', value: 1 },
]

export const platformOptions = [
  { label: 'Youtube', value: 'Youtube' },
  { label: 'Facebook', value: 'Facebook' },
]

export const platformOptionsVo = [
  { label: 'Youtube', value: 'Youtube' },
  { label: 'Facebook', value: 'Facebook' },
]

export const platformOptionsByOverseas = [
  { label: 'YouTube', value: 0 },
  { label: 'Facebook', value: 1 },
]

export const domPlatformOptions = [
  { label: '西瓜视频', value: '西瓜' },
  { label: 'B站', value: 'B站' },
  { label: '抖音', value: '抖音' },
  { label: '快手', value: '快手' },
  { label: '爱奇艺', value: '爱奇艺' },
  { label: '好看视频', value: '好看' },
  { label: '小红书', value: '小红书' },
  { label: 'YouTube', value: 'YouTube' },
  { label: 'Facebook', value: 'Facebook' },

  { label: '今日头条', value: '今日头条' },
  { label: '优酷', value: '优酷' },
  { label: 'TikTok', value: 'TikTok' },
  { label: '微博', value: '微博' },
]

export const domPlatformOptionsWithoutYT = [
  // { label: 'Youtube', value: 'youtube', disabled: true },
  { label: '西瓜视频', value: '西瓜' },
  { label: 'B站', value: 'B站' },
  { label: '抖音', value: '抖音' },
  { label: '快手', value: '快手' },
  { label: '爱奇艺', value: '爱奇艺' },
  { label: '好看视频', value: '好看' },
  { label: '小红书', value: '小红书' },
]

export const domPlatformListOptions = [
  { label: '西瓜视频', value: 'xigua' },
  { label: 'B站', value: 'bilibili' },
  { label: '抖音', value: 'douyin' },
  { label: '快手', value: 'kuaishou' },
  { label: '爱奇艺', value: 'iqiyi' },
  { label: '好看视频', value: 'haokan' },
  { label: '小红书', value: 'xiaohongshu' },
  { label: 'YouTube', value: 'youtube' },
  { label: 'Facebook', value: 'facebook' },
]

export const typeOptions = [
  { label: '影视', value: 'slice' },
  { label: '原创自制', value: 'original_own' },
  { label: '代运营', value: 'acting' },
  { label: '原创代运营', value: 'original_acting' },
  { label: '自运营', value: 'self' },
]

export const rulesOptions = [
  { label: '即时发布', value: 0 },
  { label: '相同预设发布时间', value: 1 },
  { label: '每天一个', value: 2 },
  { label: '每天两个', value: 3 },
  { label: '每周一个', value: 4 },
]

export const intervalOptions = [
  { label: '0.25', value: 0.25 },
  { label: '0.5', value: 0.5 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
]

export const operationOptions = [
  { label: '杭州FB运营组', value: 'hz_fb' },
  { label: '晋城FB运营中心', value: 'jc_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '杭州代运营', value: 'hz_mcn' },
  { label: 'YT事业部', value: 'jc_mcn' },
  { label: '杭州原创', value: 'hz_original' },
  { label: '晋城原创', value: 'jc_original' },
  { label: '上交公司', value: 'hand_over_company' },
  { label: '郑州FB孵化组', value: 'zz_incubate' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '濮阳FB孵化组', value: 'py_incubate' },
  { label: '濮阳FB运营组', value: 'py_fb' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
]

export const operationAddOptions = [
  { label: '杭州FB运营组', value: 'hz_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '杭州代运营', value: 'hz_mcn' },
  { label: 'YT事业部', value: 'jc_mcn' },
  { label: '杭州原创', value: 'hz_original' },
  { label: '晋城原创', value: 'jc_original' },
  { label: '上交公司', value: 'hand_over_company' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '濮阳FB孵化组', value: 'py_incubate' },
  { label: '濮阳FB运营组', value: 'py_fb' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
]

export const operationOptionsYT = [
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: 'YT事业部', value: 'jc_mcn' },
  { label: '杭州原创', value: 'hz_original' },
  { label: '晋城原创', value: 'jc_original' },
]

export const operationGroupOptionsByYt = [
  { text: 'YT事业部', value: 'jc_mcn' },
  { text: '杭州原创', value: 'hz_original' },
  { text: '杭州影视', value: 'hz_slice' },
  { text: '晋城原创', value: 'jc_original' },
  { text: '晋城影视', value: 'jc_slice' },
  { text: '上交公司', value: 'hand_over_company' },
  { text: '无需归属', value: 'no_need_belong' },
]

export const operationGroupOptionsByFb = [
  { text: '无运营团队', value: 'none' },
  { text: '杭州FB运营组', value: 'hz_fb' },
  { text: '晋城FB运营1组', value: 'jc_fb_1' },
  { text: '晋城FB运营2组', value: 'jc_fb_2' },
  { text: '晋城FB运营3组', value: 'jc_fb_3' },
  { text: '杭州FB孵化组', value: 'hz_incubate' },
  { text: '晋城FB孵化组', value: 'jc_incubate' },
  { text: '杭州影视', value: 'hz_slice' },
  { text: '晋城影视', value: 'jc_slice' },
]

export const operationOptionsFB = [
  { label: '杭州FB运营组', value: 'hz_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
]

export const operationOptionsFBMCN = [
  { label: '杭州FB运营组', value: 'hz_fb' },
  { label: '晋城FB运营1组', value: 'jc_fb_1' },
  { label: '晋城FB运营2组', value: 'jc_fb_2' },
  { label: '晋城FB运营3组', value: 'jc_fb_3' },
  { label: '杭州FB孵化组', value: 'hz_incubate' },
  { label: '晋城FB孵化组', value: 'jc_incubate' },
]

export const operationOptionsMovie = [
  { label: '杭州影视', value: 'hz_slice' },
  { label: '晋城影视', value: 'jc_slice' },
]

export const areaOptions = [
  { label: '日本', value: 'JP' },
  { label: '韩国', value: 'KR' },
  { label: '美国', value: 'US' },
]

export const competitorOptions = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
]
export const videoFrom = [
  { label: '创作者单视频', value: 1 },
  { label: '运营本地', value: 2 },
  { label: '奇妙工坊', value: 3 },
  { label: '创作者批量文件', value: 4 },
]

export const pageOptions = [
  { title: '每页5行', value: 5 },
  { title: '每页10行', value: 10 },
  { title: '每页15行', value: 15 },
  { title: '每页20行', value: 20 },
]

export const translateStatusOptions = [
  { label: '已保存', value: 0 },
  { label: '翻译中', value: 1 },
  { label: '翻译完成', value: 2 },
  { label: '翻译失败', value: 3 },
  { label: '上传排队', value: 4 },
  { label: '上传中', value: 5 },
  { label: '上传完成', value: 6 },
  { label: '上传失败', value: 7 },
]

export const videoCategoryOptions = [
  { text: 'SHORTS视频', value: 0 },
  { text: '中长视频', value: 1 },
  { text: '全部视频', value: 2 },
]

export const bidChannelStatusOptions = [
  { text: '正常', value: 0 },
  { text: '删除', value: 1 },
]

export const submitTeamOptions = [
  { text: '杭州原创', value: 'hz_original' },
]

export const compareEqOptions = [
  { text: '>=', value: '>=' },
  { text: '<=', value: '<=' },
]

export const pastTimeOptions = [
  { text: '过去1天', value: 86400000 },
  { text: '过去2天', value: 172800000 },
  { text: '过去7天', value: 604800000 },
  { text: '过去30天', value: 2592000000 },
]

// 运营级别
export const levelOptions = [
  { text: 'S0', value: '0' },
  { text: 'S1', value: '1' },
  { text: 'S2', value: '2' },
  { text: 'S3', value: '3' },
  { text: 'S4', value: '4' },
  { text: 'S5', value: '5' },
  { text: 'S6', value: '6' },
  { text: 'S7', value: '7' },
  { text: '无级别', value: '-1' },
]
export const querylevelOptions = [
  { text: 'S0', value: 'S0' },
  { text: 'S1', value: 'S1' },
  { text: 'S2', value: 'S2' },
  { text: 'S3', value: 'S3' },
  { text: 'S4', value: 'S4' },
  { text: 'S5', value: 'S5' },
  { text: 'S6', value: 'S6' },
  { text: 'S7', value: 'S7' },
  { text: '无级别', value: 'none' },
]
export const levelOptionsnews = [
  { text: 'S0', value: 'S0' },
  { text: 'S1', value: 'S1' },
  { text: 'S2', value: 'S2' },
  { text: 'S3', value: 'S3' },
  { text: 'S4', value: 'S4' },
  { text: 'S5', value: 'S5' },
  { text: 'S6', value: 'S6' },
  { text: 'S7', value: 'S7' },
]

export const channelOperationStatusOptions = [
  { text: '搁置', value: '1' },
  { text: '在运营', value: '0' },
]

export const trueOrFalseOptions = [
  { text: '是', value: 1 },
  { text: '否', value: 0 },
]

export const uploadStatusOptions = [
  // { text: '待下载', value: 0 },
  // { text: '下载中', value: 1 },
  // { text: '上传排队', value: 8 },
  { text: '下载排队', value: 7 },
  { text: '下载完成，上传排队', value: 2 },
  { text: '下载失败', value: 3 },
  { text: '未上传', value: 9 },
  { text: '上传中', value: 4 },
  { text: '上传完成', value: 5 },
  { text: '上传失败', value: 6 },
  { text: '已删除', value: 10 },
]

export const uploadStatusForMyTaskOptions = [
  // { text: '下载中', value: 1 },
  { text: '下载排队', value: 7 },
  { text: '下载完成，上传排队', value: 2 },
  { text: '下载失败', value: 3 },
  { text: '上传中', value: 4 },
  { text: '上传失败', value: 6 },
]

export const videoUploadIntervalOptions = [
  { text: '设置间隔时间', value: 1 },
  { text: '设置每一个时间点', value: 2 },
]

export const monetizationOptions = [
  { text: '不符合条件', value: 'VIDEO_MONETIZING_STATUS_NOT_MONETIZING_INELIGIBLE' },
  { text: '已开启', value: 'VIDEO_MONETIZING_STATUS_MONETIZING' },
  { text: '受限', value: 'VIDEO_MONETIZING_STATUS_MONETIZING_WITH_LIMITED_ADS' },
  { text: '空（频道不创收）', value: 'VIDEO_MONETIZING_STATUS_NOT_MONETIZING_CHANNEL_NOT_MONETIZING' },
  { text: '空（关闭创收）', value: 'VIDEO_MONETIZING_STATUS_NOT_MONETIZING_OFF' },
]

export const restrictionOptions = [
  { text: '版权', value: 'VIDEO_RESTRICTION_REASON_COPYRIGHT' },
  { text: '条款及政策', value: 'VIDEO_RESTRICTION_REASON_COMMUNITY_GUIDELINES' },
]

export const privacyOptions = [
  { text: '公开', value: 1 },
  { text: '不公开列出', value: 2 },
  { text: '私享', value: 3 },
  { text: '已安排时间', value: 4 },
  { text: '在部分国家/地区禁播', value: 5 },
  { text: '已移除', value: 6 },
  { text: '禁播', value: 7 },
]

export const videoStatusUpdateOptions = [
  { text: '私享转公开', value: 0 },
  { text: '公开转私享', value: 1 },
]

export const videoStatusUpdateStatusOptions = [
  { text: '已保存', value: 0 },
  { text: '上传成功', value: 1 },
  { text: '上传失败', value: 2 },
]

export const subtitleUploadStatusOptions = [
  { text: '翻译失败', value: 1 },
  { text: '翻译成功等待上传', value: 2 },
  { text: '上传中', value: 3 },
  { text: '上传失败', value: 4 },
  { text: '上传完成', value: 5 },
]

export const subtitleUploadStatusWithoutFailOptions = [
  { text: '进行中', value: 0 },
  { text: '上传中', value: 3 },
  { text: '上传失败', value: 4 },
  { text: '上传完成', value: 5 },
]

export const videoStateStatusOptions = [
  { text: '已保存', value: 0 },
  { text: '成功', value: 1 },
  { text: '失败', value: 2 },
]

export const videoStateUpdateTypeOptions = [
  { text: '私转公', value: 1 },
  { text: '公转私', value: 2 },
]

export const pageUrlStatusOptions = [
  { text: '已关注', value: 1 },
  { text: '未关注', value: 0 },
]

export const commentHandleStatusOptions = [
  { text: '已完成', value: 1 },
  { text: '未完成', value: 0 },
]

export const specificSharedAgeOptions = [
  { text: '所有人', value: null },
  { text: '15+', value: 15 },
  { text: '18+', value: 18 },
  { text: '21+', value: 21 },
  { text: '25+', value: 25 },
]

// 审核状态
export const auditStatusOptions = [
  { text: '已审核', value: 1 },
  { text: '待审核', value: 0 },
  { text: '审核中', value: 2 },
]

// 审核结果
export const auditResultOptions = [
  { text: '通过', value: '通过' },
  { text: '拒绝', value: '拒绝' },
]

// 细分审核结果
export const auditResultDetailOptions = [
  { text: 'YT通过, FB通过', value: 0 },
  { text: 'YT通过, FB拒绝', value: 1 },
  { text: 'YT拒绝, FB通过', value: 2 },
  { text: 'YT拒绝, FB拒绝', value: 3 },
]

// 拒绝原因
export const rejectReasonOptions = [
  { text: '不符合平台政策', value: 0 },
  { text: '原创度不足/版权模糊', value: 1 },
  { text: '内容吸引力低', value: 2 },
  { text: '缺乏价值性/市场供需低', value: 3 },
  { text: '画面质量低', value: 4 },
  { text: '音频处理不专业', value: 5 },
  { text: '账号/链接问题', value: 6 },
  { text: '频道数据未达标', value: 7 },
  { text: '重复提交', value: 8 },
]

export const overseasPlatformOptions = [
  { text: 'YouTube', value: 'youtube' },
  { text: 'Facebook', value: 'facebook' },
]

export const channelOriginOptions = [
  { text: '商务自捞', value: 0 },
  { text: '技术捞取', value: 1 },
  { text: '达人推荐', value: 2 },
  { text: '运营推荐', value: 3 },
]

export const worksTypeOptions = [
  { text: '视听作品', value: 1 },
  { text: '图文作品', value: 2 },
  { text: '音乐作品', value: 3 },
]

export const worksType = [
  // { label: '频道', value: 3 },
  { label: '视听作品', value: 0 },
  { label: '图文作品', value: 1 },
  { label: '音乐作品', value: 2 },
  { label: '影视作品', value: 5 },
]

export const channelTypeOptions = [
  { text: '主频道', value: 0 },
  { text: '子频道', value: 1 },
]

// 频道评级
export const channelRatingOptions = [
  { text: 'S', value: 'S' },
  { text: 'A', value: 'A' },
  { text: 'B', value: 'B' },
  { text: 'C', value: 'C' },
  { text: 'D', value: 'D' },
]

export const authorizeRegionOptions = [
  { text: '中国大陆地区以外的区域（包含中国香港自治区、澳门自治区、台湾省）', value: 0 },
  { text: '自定义限制区域', value: 1 },
]

export const authorizePlatformOptions = [
  { text: 'YouTube平台', value: 0 },
  { text: 'Facebook平台', value: 1 },
  { text: 'Instagram', value: 2 },
  { text: 'Threads', value: 3 },
  { text: 'X', value: 4 },
  { text: 'TikTok', value: 5 },
  { text: 'Snapchat', value: 6 },
  { text: 'Dailymotion', value: 7 },
  { text: '海外自媒体全平台', value: 8 },
]

// 0探索记录类、1影视剧、2游戏体育类、3少儿动漫类、4娱乐综艺类、5创新个性类、6人文科普类、7时政军事类
export const fieldOptions = [
  {
    text: '探索记录类',
    value: 0,
  },
  {
    text: '影视剧',
    value: 1,
  },
  {
    text: '游戏体育类',
    value: 2,
  },
  {
    text: '少儿动漫类',
    value: 3,
  },
  {
    text: '娱乐综艺类',
    value: 4,
  },
  {
    text: '创新个性类',
    value: 5,
  },
  {
    text: '人文科普类',
    value: 6,
  },
  {
    text: '时政军事类',
    value: 7,
  },
]

export const channelMultipleOptions = [
  { text: '单开', value: 1 },
  { text: '合集', value: 0 },
]

export const operationModeOptions = [
  { text: '代运营', value: 0 },
  { text: '自运营', value: 1 },
  { text: '代运营+制作', value: 2 },
]

// 合约状态
export const contractStatusOptions = [
  { text: '解约', value: 1 },
  { text: '合约中', value: 0 },
]

// 频道状态
export const channelStates = [
  { text: '正常运行', value: 1 },
  { text: '未达标', value: 2 },
  { text: '审核中', value: 3 },
  { text: '获利被关', value: 4 },
  { text: '获利审核未通过', value: 5 },
  { text: '频道被封', value: 6 },
  { text: '已移除', value: 0 },
]

// CMS关联
export const CMSNet = [
  { text: '是', value: 1 },
  { text: '否', value: 0 },
]

// CMS名称
export const CMSName = [
  { text: '小五兄弟', value: 'p3Pw4Wu5DjqCkWQqAcyWJA' },
  { text: '亚洲创客', value: 'bRQT0F0-BzoaByQmJY-PQw' },
]

// 获利变化：下拉列表。可选项：是，否 1变 0不变
export const makeStates = [
  { text: '是', value: 1 },
  { text: '否', value: 0 },
]

// facebook 内容分类
export const contentClass = [
  { text: '真人秀', value: 0 },
  { text: '二创', value: 1 },
  { text: '影视版权', value: 2 },
]

// facebook 主页属性
export const homepageProperies = [
  { text: '影视代运营', value: 'slice' },
  { text: '代运营', value: 'acting' },
  { text: '原创代运营', value: 'original_acting' },
  { text: '自运营-符合变现地区所在国家', value: 'self_acc_country' },
  { text: '自运营-不符合变现地区所在国家', value: 'self_not_acc_country' },
  { text: '爱奇艺', value: 'aqiyi' },
  { text: '江苏卫视', value: 'jsbc' },
  { text: '代运营+制作', value: 'acting_make' },
]

// facebook 查询 主页属性
export const queryhomepageProperies = [
  { text: '影视代运营', value: 'slice' },
  { text: '代运营', value: 'acting' },
  { text: '原创代运营', value: 'original_acting' },
  { text: '自运营-符合变现地区所在国家', value: 'self_acc_country' },
  { text: '自运营-不符合变现地区所在国家', value: 'self_not_acc_country' },
  { text: '爱奇艺', value: 'aqiyi' },
  { text: '江苏卫视', value: 'jsbc' },
  { text: '无主页属性', value: 'none' },
  { text: '代运营+制作', value: 'acting_make' },
]

// youtube 运营类型
export const operationModeYTOptions = [
  { text: '自运营', value: 'self' },
  { text: '代运营', value: 'acting' },
  { text: '代运营+制作', value: 'acting_make' },
]

// facebook 主页状态
export const homepageStatus = [
  { text: '使用中', value: 1 },
  { text: '已移除', value: 0 },
]

// facebook  搁置
export const shelveOptions = [
  { text: '是', value: 1 },
  { text: '否', value: 0 },
]

export const operationGroupOptionsByFbnews = [
  { text: 'FB事业部', value: 'hz_fb' },
  { text: '杭州影视', value: 'hz_slice' },
  { text: '晋城影视', value: 'jc_slice' },
  { text: '无运营团队', value: 'none' },
]

export const operationGroupOptionsByYtnews = [
  { text: 'YT事业部', value: 'jc_mcn' },
  { text: 'FB事业部', value: 'hz_fb' },
  { text: '国际事业部', value: 'hz_original' },
  { text: '杭州影视', value: 'hz_slice' }, // { text: '晋城原创', value: 'jc_original' },
  { text: '晋城影视', value: 'jc_slice' },
  { text: 'TK事业部', value: 'hz_tiktok' },
  { text: '上交公司', value: 'hand_over_company' },
  { text: '无需归属', value: 'no_need_belong' },
]

export const videoSuffix = ['.mov', '.mpeg-1', '.mpeg-2', '.mpeg4', '.mp4', '.mpg', '.avi', '.wmv', '.mpegps', '.flv', '.3gpp', '.webm', '.dnxhr', '.prores', '.cineform']
export const thumbnailSuffix = ['.jpg', '.jpeg', '.png']
export const subtitleSuffix = ['.srt']

// 发布情况
export const publishStatusOptions = [
  { text: '发布至YT', value: 0 },
  { text: '发布至FB', value: 1 },
  { text: '未发布', value: 2 },
]

// 注册频道类型
export const registerChannelTypeOptions = [
  { text: '新开', value: 0 },
  { text: '自带', value: 1 },
  { text: '合并', value: 2 },
]

// 注册频道状态
export const registerStatusOptions = [
  { text: '已撤回', value: 8 },
  { text: '待审核', value: 2 },
  { text: '审核拒绝', value: 0 },
  { text: '待关联', value: 3 },
  { text: '待建群', value: 4 },
  { text: '已完成', value: 9 },
]

export const registerChannelStatusOptions = [
  { text: '待处理', value: 0 },
  { text: '已处理', value: 1 },
]

// 注册类型
export const registerTypeOptions = [
  { text: '新开', value: 0 },
  { text: '自带', value: 3 },
  { text: '合并', value: 1 },
  { text: '替换', value: 2 },
]

// 签约平台
export const contractPlatformOptions = [
  { text: '抖音', value: '抖音' },
  { text: '西瓜', value: '西瓜' },
  { text: '快手', value: '快手' },
  { text: 'B站', value: 'B站' },
  { text: '好看', value: '好看' },
  { text: '小红书', value: '小红书' },
  { text: '爱奇艺', value: '爱奇艺' },
  { text: 'YouTube', value: 'YouTube' },
  { text: 'Facebook', value: 'Facebook' },
]

// 运营平台
export const operationPlatformOptions = [
  { text: 'YouTube', value: 'YouTube' },
  { text: 'Facebook', value: 'Facebook' },
  { text: 'Tiktok', value: 'Tiktok' },
  { text: 'Instagram', value: 'Instagram' },
  { text: 'X', value: 'X' },
  { text: 'Threads', value: 'Threads' },
  { text: '今日头条', value: '今日头条' },
  { text: 'B站', value: 'B站' },
  { text: '爱奇艺', value: '爱奇艺' },
  { text: '好看', value: '好看' },
  { text: '京东', value: '京东' },
  { text: '支付宝', value: '支付宝' },
  { text: '美团', value: '美团' },
  { text: '拼多多', value: '拼多多' },
  { text: '微信视频号', value: '微信视频号' },
  { text: 'QQ短视频', value: 'QQ短视频' },
  { text: '淘宝逛逛', value: '淘宝逛逛' },
  { text: '其他', value: '其他' },
]

export const periodOptions = [
  { text: '1-3个月', value: 0 },
  { text: '3-6个月', value: 1 },
  { text: '6-12个月', value: 2 },
  { text: '12个月以上', value: 3 },
]

export const publishTimeOptions = [
  { text: '8:00-12:00', value: 0 },
  { text: '12:00-16:00', value: 1 },
  { text: '16:00-20:00', value: 2 },
  { text: '20:00-0:00', value: 3 },
  { text: '0:00-4:00', value: 4 },
  { text: '4:00-8:00', value: 5 },
]

export const operateModeOptions = [
  { text: '机器代运营', value: 0 },
  { text: '人机混合代运营', value: 1 },
  { text: '创作者自运营', value: 2 },
]

// 匹配政策
export const cidPolicyOpts = [
  {
    value: 'S661566637357110',
    text: '没收获利',
  },
  {
    value: 'S850496974783346',
    text: '禁播',
  },
]

// 频道评级 最新 线索 新增其他其他
export const clueChannelRatingOptions = [
  { text: 'S', value: 'S' },
  { text: 'A', value: 'A' },
  { text: 'B', value: 'B' },
  { text: 'C', value: 'C' },
  { text: 'D', value: 'D' },
  { text: '其他', value: '其他' },
]

// 线索审核table 显示 审核状态
export const clueAuditStatusOptions = [
  { text: '已审核', value: 1 },
  { text: '待审核', value: 0 },
  { text: '审核中', value: 2 },
  { text: '已审核', value: 4 },
]
