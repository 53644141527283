<script>
import {
  computed,
  watch,
} from '@vue/composition-api'

export default {
  name: 'DatePickerForm',
  components: {
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [Array, String],
      default() {
        return []
      },
    },
    required: Boolean,
    type: {
      type: String,
      default: 'daterange',
    },
    range: {
      type: Boolean,
      default: false,
    },
    pickerOptions: {
      type: Object,
      default: () => ({}),
    },
    defaultTime: {
      type: [Array, String],
      default: () => ['00:00:00', '23:59:59'],
    },
    valueFormat: {
      type: [String],
      default: 'yyyy-MM-dd',
    },
  },
  setup(props, { emit }) {
    const datetime = {
      shortcuts: [
        {
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date())
          },
        },
        {
          text: '昨天',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          },
        },
        {
          text: '一周前',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          },
        },
      ],
    }
    const datetimerange = {
      shortcuts: [
        {
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          },
        },
        {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          },
        },
        {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          },
        },
      ],
    }
    const types = {
      datetime,
      datetimerange,
      daterange: datetimerange,
    }

    // 数据兼容
    const newPickerOptions = computed(() => {
      if (Object.keys(props.pickerOptions).length > 0) {
        return props.pickerOptions
      }

      return types[props.type] || []
    })
    const timePick = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    watch(() => props.value, () => {
      if (props.range && !props.value)emit('input', [])
      if (!props.range && !props.value)emit('input', '')
    })

    return {
      newPickerOptions,
      timePick,
    }
  },
}
</script>

<template>
  <div class="date-picker">
    <el-date-picker
      v-model="timePick"
      :type="type"
      :picker-options="newPickerOptions"
      placeholder="请选择时间"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      prefix-icon="none"
      :default-time="defaultTime"
      :value-format="valueFormat"
      align="center"
      style="width: 100%; max-width: 250px;"
      v-bind="$attrs"
    />
  </div>
</template>

<style scoped lang="scss">
.date-picker {
  width: 100%;
  position: relative;
}
.el-date-editor--datetimerange {
  display: flex;
  ::v-deep .el-range-input {
    flex: auto;
  }

  ::v-deep .el-range__close-icon {
    display: none;
  }

  ::v-deep .el-icon-circle-close {
    display: block !important;
  }
}

.el-range-editor--datetime {
  display: flex;
  ::v-deep .el-range-input {
    flex: auto;
  }

  ::v-deep .el-range__close-icon {
    display: none;
  }

  ::v-deep .el-icon-circle-close {
    display: block !important;
  }
}

.el-range-editor.is-active {
  border-color: var(--v-primary-base) !important;
}
.el-range-editor.el-input__inner {
  padding: 3px 6px;
}
::v-deep .el-input--prefix .el-input__inner {
  padding: 3px 6px !important;
  padding-right: 20px !important;
}
</style>
