import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'

import {
  Popover,
  Button,
  ButtonGroup,
  Icon,
  Dialog,
  Form,
  FormItem,
  Select,
  Option,
  DatePicker,
  Tabs,
  TabPane,
} from 'element-ui'

Vue.use(Popover)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Tabs)
Vue.use(TabPane)
